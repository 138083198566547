// Styles
* { 
  text-align: center;
  cursor: pointer;
}
body {
  min-height: 100vh;
  color: white;
  font-family: $main-font-family;
  @include customBackground($radial-background-color, 5px, 0.5);
  -webkit-font-smoothing: antialiased;
}
body, h1, h2, h3, p, ol, ul, a {
  margin: 0;
}
main {
  min-height: 72vh;  
  box-sizing: border-box;
  margin: 60px auto;
  @include tablet {
    max-width: 992px;
  }
  @include mobil-and-tablet {
    padding: 25px;
}
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
p, ol, ul, a {
  font-size: 1.25rem;
}
ol, ul {
  list-style-type: none;
}
a {
  color: white;
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 10px;
  position: absolute;
}
::-webkit-scrollbar-button,
::-webkit-scrollbar-track {    
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: $yellow-color;
  border-radius: 6px;
}
::-webkit-scrollbar-corner {
  border-radius: 15px;
}  