// Logo

@keyframes tagAnimation {
  from {
    transform: translateX(0%);
    opacity: 0;
  }
  to {
    transform: translateX(100%);
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes cycle {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}