.main-Accueil {
    max-width: 100vw;
    @include box-center;
    flex-direction: column;
    padding: 0 1rem;
    h1 {
        margin-bottom: 50px;
    }
    .introduction {
        margin-bottom: 1rem;
        &__sloggan {
            margin-bottom: 2rem;
        }
        &__cta {
            color: $yellow-color;
            font-size: 0.9rem;
        }
    }
}