.main-About {
    max-width: 90vw;
    @include box-center;
    flex-direction: column;
    gap: 50px;
    padding: 0 1rem;
    .section-About {
        display: flex;
        justify-content: center;
        gap: 100px;
        padding: 2rem;
        &__hero {
            border-radius: 15px;  
            position: relative;         
            img {
                width: 400px;
                display: flex;
                border-radius: 15px;
                box-shadow: 2px 1px 30px 0 rgba(196,155,71,1);
                @include mobil-and-tablet {
                    width: 200px;
                    height: 200px;
                    object-fit: cover;
                    object-position: top;
                    border-radius: 100%;
                }
            }
            .credit, .credit-link {
                color: black;
                font-size: 0.6rem;
                @include mobil-and-tablet {
                    display: none;
                }
            }
            .credit {
                writing-mode: vertical-lr;
                position: absolute;
                top: 10px;
                left: 5px;
                .credit-link {
                    margin-top: 3px;
                }
            }
            
        }
        &__presentation {
            max-width: 50%;
            @include box-column;
            gap: 50px;
            @include mobil-and-tablet {
                max-width: 100%;
            }
            article {  
                @include box-column;
                align-items: flex-start;
                gap: 30px;
                h2 {
                    text-align: left;
                }
            }
            .section-About__presentation-text {
                p {
                    text-align: left;
                }
            }
        }
        @include mobil-and-tablet {
            flex-direction: column;
            align-items: center;
        }
    }
}
