.slider {
  margin: 0 auto 50px auto;
  @include mobil-and-tablet {
    max-width: 95%;
    margin: 0;
  }
  .slider-list {
    @include box-center;
    padding: 0;
    margin: 0;
    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @include mobil {
      display: grid;
      grid-template-columns: 1fr;
    }
    &__item {
      width: 25%;
      margin: 0;
      @include mobil-and-tablet {
        width: 100%;
      }
      .slider-list__item-figure {
        position: relative;
        margin: 0; 
        border: 1px white solid; 
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background: radial-gradient(circle at left, black 38%, transparent 100%);
          opacity: 0.5;
          transition: opacity 0.3s ease;
        }
        &:hover::before {
          opacity: 0;
        }
      }
      .slider-list__item-figure:hover {
        transform: scale(1.2);
        z-index: 3;
        @include tablet {
          transform: scale(1.08);
        }
        @include mobil {
          transform: scale(1.05);
        }
      }
      .slider-list__item-image {
        width: 100%;
        height: 18rem;
        filter: blur(3px) brightness(0.35);
        object-fit: cover;
      }
      .slider-list__item-title {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        margin: 0;
      }
    }
  }
}