// Flex-box
@mixin box-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin box-column {
  display: flex;
  flex-direction: column;
}

// Backgrounds components
@mixin customBackground($radial-background-color, $backdrop-filter-blur, $overlay-opacity) {
  background: $radial-background-color, url("../../assets/background-image.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur($backdrop-filter-blur);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, $overlay-opacity);
  }
}

@mixin background-collapse($url, $brightness: 0.5) {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url($url);
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  filter: brightness($brightness);
  z-index: -1;
}

@mixin background-button-contact($url, $transform: none) {
  background-image: url($url); 
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: brightness(0.3);
  z-index: -1;
  transform: $transform; 
}

// Responsive
@mixin mobil-and-tablet {
  @media screen and (max-width: #{$mobil-max}), (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
    @content;     
  }
}
  
@mixin mobil {
    @media screen and (max-width: #{$mobil-max}) {
      @content;     
    }
  }
  
@mixin tablet {
  @media screen and (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
    @content;     
  }
}
  