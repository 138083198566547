// Logo
.logo__img {
  width: 70px;
  height: 70px;
  border-radius:50px;
  box-shadow: 1px 1px 25px $yellow-color;
}
// Logo in-Header
.logo.header {
  width: 250px;
  display: flex;
  @include mobil {
    width: 100px;
  }
  .logo__wrapper {
    animation: cycle 10s linear infinite;
    @include box-center;
    @include mobil {
      animation: none;
    }
  }
  .logo__tags {
    padding: 0;
    @include mobil {
      display: none;
    }
  }
}
.logo__tags li {
  position: absolute;
  top: 35px;
  left: 100px;
  opacity: 0;
}

.logo__tags li:nth-child(1) {
  animation: tagAnimation 0.5s 0.5s forwards, fadeOut 0.5s 2s forwards;
}
.logo__tags li:nth-child(2) {
  animation: tagAnimation 0.5s 2s forwards, fadeOut 0.5s 3.5s forwards;
}
.logo__tags li:nth-child(3) {
  animation: tagAnimation 0.5s 3.5s forwards, fadeOut 0.5s 5s forwards;
}
.logo__tags li:nth-child(4) {
  animation: tagAnimation 0.5s 5s forwards, fadeOut 0.5s 6.5s forwards;
}
.logo__tags li:nth-child(5) {
  animation: tagAnimation 0.5s 6.5s forwards, fadeOut 0.5s 8s forwards;
}
.logo__tags li:nth-child(6) {
  animation: tagAnimation 0.5s 8s forwards, fadeOut 0.5s 9.5s forwards;
}
.logo__tags li:nth-child(7) {
  animation: tagAnimation 0.5s 9.5s forwards, fadeOut 0.5s 11s forwards;
}

// Logo in Footer
.logo.footer {
  .logo__tags li {
    animation: none;
  }
}