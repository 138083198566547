// /* modal.css */  
.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
}
  
.modal {
    width: 70%;
    max-width: 1200px;
    height: 38rem;
    color: black;
    background: #fff;
    border-radius: 15px;
    position: sticky;
    top: 25%;
    left: 50%;
    transform: translate(-25%, -25%);
    padding: 30px;
    box-sizing: border-box;
    overflow: scroll;
    @include mobil-and-tablet {
      width: 90%;
      height: auto;
      transform: translate(-5%, -25%);
    }
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  cursor: pointer;
  border: none;
  background: transparent;
}

// Modal Slider
.slider-modal {
  .modal-container {
    width: 100%;
    height: 100%;
    @include box-column;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    &__button-link {
      width: 15rem;
      color: black;
      font-family: $second-font-family;
      background-color: $yellow-color;
      border-radius: 15px;
      border: none;
      padding: 15px 25px;
      @include mobil {
        width: 80%;
      }
    }
    &__button-link:hover {
      transform: scale(1.05);
    }
    &__list {
      font-style: italic; 
      display: flex;
      position: relative;
      @include mobil {
        display: none;
      }
    }
    &__list::after {
      content: "";
      width: 200px;
      height: 1px;
      background-color: $yellow-color;
      position: absolute;
      left: 50%;
      top: 80px;
      transform: translate(-50%, -50%);
    }
    &__list-skills {
      display: inline-flex;
      gap: 15px;
      padding: 0;
    }
  }
}

// Modal FAQ
.modal.faq-modal {
  @include box-column;
  gap: 30px;
  h2 {
    text-align: center;
  }
  .modal-description {
    @include box-column;
    align-items: flex-start;
    gap: 15px;
    &::before {
      content: "";
      width: 200px;
      height: 1px;
      background-color: $yellow-color;
    }
    &__answer {
      font-weight: 800;
    }
    &__response {
      font-style: italic;
      text-align: justify;
    }
  }
}

// Modal Contact
.modal-contact {
  width: 60%;
  height: 60%;
  @include box-center;
  flex-direction: column;
  gap: 50px;
  @include mobil-and-tablet {
    width: 80%;
    height: auto;
    padding: 20px;
  }
  h2 {
     @include mobil-and-tablet {
      margin-top: 50px;
     }
  }
  .modal-link {
    color: black;
    font-size: 1.5rem;
  }
  .sending-link {
    font-family: $second-font-family;
    background-color: rgb(185 141 48);
    padding: 15px;
    border-radius: 10px;
  }
  .sending-link:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 15px $light-blue-color;
  }
}