header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 40px;
  border-bottom: grey 3px solid;
  .nav-menu {
    width: 60%;
    font-family: $second-font-family;
    letter-spacing: 1.5px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-right: 0;
    .hamburger-menu {
      cursor: pointer;
      display: none;
    }
    .line {
      width: 30px;
      height: 3px;
      background-color: #333;
      margin: 6px 0;
    }
    .nav-links {
      width: 100%;
      font-family: $second-font-family;
      letter-spacing: 1.5px;
      display: flex;
      justify-content: space-between;
      position: relative;
      a.active {
        position: relative;
      }
      a.active::after {
        content: "";
        position: absolute;
        bottom: -36px;
        left: 0;
        right: 0;
        height: 8px;
        border-radius: 10px;
        background-color: white;
      }
    }
  }
  @include mobil-and-tablet {
    .nav-menu {
      justify-content: flex-end;
      .hamburger-menu {
        display: block;
        .line {
          width: 50px;
          height: 5px;
          background-color: $yellow-color;
        }
      }
      .nav-links {
        display: none;
        position: absolute;
        top: 100px;
        left: 0;
        z-index: 10;
        width: 50%;
        background-color: $yellow-color;
        a {
          padding: 15px;
          text-align: center;
          &.active::after {
            display: none;
          }
        }
      }
      &.open {
        .nav-links {
          display: flex;
          border-radius: 20px;
          @include mobil {
            width: 100%;
            flex-direction: column;
          }
          a:hover {
            color: $yellow-color;
            background-color: white;
            border-radius: 20px;
          }
        }
      }
    }
  }
}