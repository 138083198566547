.collapse {
  height: 35rem;
  color: black;
  background: #f1f1f1;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  @include mobil {   
    width: 90vw; 
    height: auto;
    @include box-column;
  }
  .collapse-header {
    cursor: pointer;
    width: 120px;
    color: white;
    border: 1px solid $yellow-color;
    border-radius: 10px;
    writing-mode: vertical-lr;
    box-sizing: border-box;
    padding: 25px 20px 0px 20px;
    transform: rotate(-180deg);
    display: flex;
    @include mobil {
      width: 100%;
      height: 120px;
      writing-mode: horizontal-tb;
      transform: rotate(0deg);
      padding: 20px 25px;
    }
    h2 {
      font-family: $second-font-family;
    }
  }
  .collapse-content {
    width: 0;
    opacity: 0;
    transform: translateX(300px);
    overflow: hidden;
    box-sizing: border-box;
    transition: width 0.4s ease-in-out, padding 0.2s ease,  opacity 0.75s ease, transform 1s ease;
    padding: 25px 0 15px 0;
    @include mobil {
      height: 0;
      width: auto;
      transform: translateY(200px);
      transition: height 0.4s ease-in-out;
      padding: 0;
    }
  }
} 
.collapse.expanded {
  overflow: hidden;
  .collapse-content.visible {
    width: 600px;
    overflow: scroll;
    opacity: 1;
    transform: translateX(0);
    @include box-column;
    align-items: flex-start;
    gap: 35px;
    padding: 25px;
    @include mobil {
      width: 100%;
      height: 600px;
      padding: 20px;
    }
    ol {  
      list-style-type: decimal;    
      padding: 15px;
      li {
        text-align: justify;
        margin-bottom: 10px;
      }
    }
  }
}
.background1::before {
  @include background-collapse("../../assets/Services-creation.webp", 0.5)
} 
.background2::before {
  @include background-collapse("../../assets//Services-integrations.webp", 0.5)
}
.background3::before {
  @include background-collapse("../../assets/Services-animations.webp", 0.5)
}

// Collapses Footer
.conditionsGU, 
.conditionsGV {
  @include box-column;
  gap: 8px;
  .collapse {
    height: auto;
    @include box-column;
    box-shadow: 5px 3px 10px black;
    .collapse-header {
      background-color: $yellow-color;
      width: 100%;
      height: 120px;
      box-shadow: 5px 5px 10px black;
      writing-mode: horizontal-tb;
      transform: rotate(0deg);
      padding: 20px 25px;
      h2 {
        font-family: $main-font-family;
        @include mobil-and-tablet {
          font-size: 1.5rem;
        }
      }
    }
    .collapse-content {
      height: 0;
      width: auto;
      padding: 0;
    }
  }
  .collapse.expanded {
    .collapse-content.visible {
      width: 100%;
      height: auto;
      padding: 20px;
      p {
        @include mobil-and-tablet {
          font-size: 1rem;
        }
      }
    }
  }
}