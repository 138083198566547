.main-Services {
    max-width: 90vw;
    display: grid;
    grid-template-columns: 30% 70%;
    align-content: center;
    @include mobil {
        @include box-center;
        flex-direction: column;
    }
    .services-description {
        @include box-column;
        align-items: flex-start;
        justify-content: center;
        gap: 4rem;
        p {
            color: $yellow-color;
            text-align: left;
            margin: 0;
            @include mobil {
                display: none;
            }
        }
    }
    .services-items { 
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        margin: 0;
        @include mobil {
            flex-direction: column;
        }
        &__price {
            font-size: 0.9rem;
            font-style: italic;
        }
    }
}