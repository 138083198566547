// Polices
$main-font-family: "Nunito";
$second-font-family: "Saira Stencil One";

// Colors
$light-blue-color:#4cb0a9fe;
$yellow-color:#c49b47;
$radial-background-color: radial-gradient(circle, rgba(76,176,169,0.7) 0%, rgba(0,0,0,0.9) 100%);

// Breakpoints
$mobil-max: 819px;
$tablet-min: 820px;
$tablet-max: 1023px;
