.button-more {
    font-size: 1.5rem;
    font-family: $main-font-family;
    width: 300px;
    height: 40px;
    background: white;
    box-shadow: 5px 5px 25px white;
    border-radius: 50px;
    border: none;
    transition: transform 0.2s ease; 
    &:hover {
        transform: scale(1.03);
    }
    @include mobil-and-tablet {
        display: none;
    }
}
.button-link {
    font-size: 1.5rem;
    font-family: $second-font-family;
    color: black;
    width: 300px;
    height: 40px;
    background: $yellow-color;
    box-shadow: 5px 3px 10px black;
    border-radius: 25px;
    border: none;
    transition: transform 0.2s ease; 
    margin: 0 auto;
    @include mobil {
        @include box-center;
        width: 70%;
        height: auto;
        font-size: 1.1rem;
    }
    &:hover {
        transform: scale(1.03);
    } 
}
