footer {
    height: auto;
    background-color: $yellow-color;
    @include box-center;
    padding: 15px;
    box-sizing: border-box;
    @include mobil-and-tablet {
        gap: 30px;
    }
    @include mobil {
        height: auto;
        flex-direction: column;
    }
}
.footer-left-section,
.footer-right-section {
    width: 50%;
    margin: 0;
    @include mobil {
        width: 100%;
    }
    p, ul {
        font-size: 1rem;
    }
}
.footer-left-section{
    padding: 0 30px;
    p {
        @include mobil-and-tablet {
            font-size: 0.9rem;
        }
    }
}
.footer-right-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1.5rem;
    @include mobil {
        margin-right: 0;
        flex-direction: column;
        justify-content: center;
    }
    ul {
        display: flex;
        gap: 100px;
        @include mobil {
            width: 100%;
            justify-content: space-around;
            padding-inline-start: 0;
        }
    }
}
