.main-Contact {
    @include box-center;
    flex-direction: column;
    gap: 50px;
    @include mobil-and-tablet {
        padding: 0;
    }
}
.contact-items {
    width: 75%;
    height: 40rem;
    position: relative;
    display: flex;
    justify-content: center;
    @include mobil-and-tablet {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    &__left-part {
        width: 30%;
        display: flex;
        flex-direction: row;
        @include mobil-and-tablet {
            width: 70%;
            flex-direction: column;
        }
        button {
            width: 50%;
            writing-mode: vertical-rl;
            box-shadow: 5px -5px 15px white;
            transform: rotate(180deg);
            padding: 25px 10px;
            @include mobil-and-tablet {
                width: 100%;
                height: 100px;
                transform: none;
                writing-mode: unset;
            }
        }
    }
    &__right-part {
        width: 70%;
        @include box-column;
        button {
            height: 50%;
            box-shadow: -5px 5px 15px white;
            padding: 10px 25px;
            @include mobil-and-tablet {
                height: 100px;
                box-shadow: 5px -5px 15px white;
            }
        }
    }
}
.contact-items__left-part button, 
.contact-items__right-part button {
    color: white;
    font-size: 2.25rem;
    font-family: $second-font-family;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: flex-end;
}
.phone::before {
    @include background-button-contact("../../assets/smartphone.webp", rotate(180deg))
}
.mail::before {
    @include background-button-contact("../../assets/mail.webp", rotate(180deg))
} 
.linkedin::before {
    @include background-button-contact("../../assets/linkedin.webp")
}
.github::before {
    @include background-button-contact("../../assets/github.webp")
}