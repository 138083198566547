.footer.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at top left, rgba(76, 176, 169, 1) 0%, rgba(0, 0, 0, 1) 100%);
  overflow: scroll;
}

.footer-modal {   
  width: 50%;
  display: none;
  box-sizing: border-box;
  padding: 100px 60px;
  margin: 0 auto;
  @include mobil-and-tablet {
    padding: 25px 20px;
  }
}
  
.footer-modal.open {
  width: 90%;
  @include box-center;
  flex-direction: column;
  gap: 50px;
  @include mobil-and-tablet {
    width: 100%;
  }
  .modal-close {
    color: white;
    position: absolute;
    top: 1.75rem;
    right: 2rem;
    font-size: 3.75rem;
    cursor: pointer;
    border: none;
    background: transparent;
    @include mobil-and-tablet {
      font-size: 2.5rem;
    }
  }
}
.mentions-container {
  @include box-column;
  gap: 60px;
  @include mobil {
    width: 100%;
    margin-top: 100px;
  }
  article {
    @include box-column;
    align-items: flex-start;
    gap: 15px;
    p {      
      @include mobil-and-tablet {
        text-align: left;
      }
    }    
  }
}
.conditionsGU {
  @include mobil {
    width: 100%;
    margin-top: 100px;
  }
  h2 {
    margin-bottom: 50px;
    @include mobil-and-tablet {
      font-size: 1.7rem;
    }
  }
}
  